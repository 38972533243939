import { useSignInWithGoogle } from "react-firebase-hooks/auth";
import { auth } from "../../config/firebase";
import { Button, Stack } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";

const Auth = () => {
  const [signInWithGoogle, , loadingGoogle] = useSignInWithGoogle(auth);

  return (
    <Stack className="flex items-center justify-center h-full">
      <Button
        size="lg"
        color="teal"
        rightSection={<IconArrowRight size={18} />}
        onClick={() => signInWithGoogle()}
        loading={loadingGoogle}
      >
        Se connecter avec Google
      </Button>
    </Stack>
  );
};

export default Auth;
